import { render, staticRenderFns } from "./WidgetTable.vue?vue&type=template&id=6913d54b"
import script from "./WidgetTable.vue?vue&type=script&lang=js"
export * from "./WidgetTable.vue?vue&type=script&lang=js"
import style0 from "./WidgetTable.vue?vue&type=style&index=0&id=6913d54b&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionBackgroundCard: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionBackgroundCard.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,Cta: require('/app/components/Widget/components/Cta.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
