
import Cta from "./components/Cta.vue";

export default {
  components: { Cta },

  props: {
    label: {
      type: String,
      default: "",
    },

    colorForeground: {
      type: String,
      required: true,
    },

    colorBackground: {
      type: String,
      required: true,
    },
  },
};
